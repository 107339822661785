import type React from 'react'
import type { ImageLoader } from 'next/image'
import Image from 'next/image'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { useSticky } from '@cms/hooks/useSticky'
import { FALLBACK_IMAGES, SMART_CROP_TOKENS } from '@cms/utils/constants'
import {
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
  toKebabCase,
} from '@cms/utils/utils'
import type { Document, Text } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'
import { ImageWrapper } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/utils/constants'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import RichText from '../RichText'
import type { SidebarEntryProps } from '../TextSidebarMenu'
import TextSidebarMenu from '../TextSidebarMenu/TextSidebarMenu'
import { useStyles } from './TextBody.styles'
import type { TextBodyProps } from './TextBody.types'

// empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
const getImageLoader = (isProductImage: boolean, devicePixelRatio: number): ImageLoader => {
  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  return isProductImage
    ? getRenditionImageLoader(devicePixelRatioPriority)
    : getSmartCropImageLoaderByTokenName(SMART_CROP_TOKENS.CF_4X3_S, devicePixelRatioPriority)
}

const setImageLoader = (isProductImage, devicePixelRatio) =>
  getImageLoader(isProductImage, devicePixelRatio)

const isEmptyRichText = (richTextDocument: Document) =>
  !richTextDocument ||
  (richTextDocument?.content?.length === 1 &&
    (richTextDocument.content?.[0].content?.[0] as Text).value === '')

export const TextBody: React.FC<TextBodyProps> = ({
  richTextDocument,
  showSidebarMenu = false,
  image,
  imageAlignment,
  isProductImage,
}) => {
  const theme = useTheme()
  const { headerHeightWithOffsetTop } = useGlobalContext()
  const styles = useStyles(theme, imageAlignment, isProductImage)

  const { ref, isSticky } = useSticky<HTMLDivElement>(headerHeightWithOffsetTop)

  const hasRichTextTable = richTextDocument?.content?.some(
    (data) => data?.nodeType === BLOCKS.TABLE,
  )

  const { devicePixelRatio } = useDevicePixelRatio()

  let sidebarHeadlines: SidebarEntryProps[]

  if (!isEmptyRichText(richTextDocument)) {
    const textSidebarHeadlineNodes = [BLOCKS.HEADING_2, BLOCKS.HEADING_3]

    const formatRichTextHeadlines = (headlineContent, index): SidebarEntryProps => {
      const text = (headlineContent.content[0] as Text).value
      return {
        id: index + text,
        node: headlineContent.nodeType,
        link: `#${toKebabCase(text)}`,
        text,
      }
    }

    sidebarHeadlines = richTextDocument.content
      .filter((content) => textSidebarHeadlineNodes.indexOf(content.nodeType) !== -1)
      .map(formatRichTextHeadlines)
  }

  const defaultContentGridSize = 8
  const maxGridSize = 12
  const shouldSetAutoMargin = (!showSidebarMenu && !hasRichTextTable) || image // when content is only text(no table) without a sidebar OR when there is an image
  const sectionHeaderTopOffset = 72

  return (
    !isEmptyRichText(richTextDocument) && (
      <Container sx={WEB_CONTAINER_VERTICAL_SPACE}>
        <Grid container data-cy="text">
          {showSidebarMenu && (
            <>
              <Grid
                ref={ref}
                item
                xs={maxGridSize}
                lg={3}
                sx={{
                  position: 'sticky',
                  background: 'white',
                  alignSelf: 'start',
                  zIndex: 1,
                  top: {
                    xs: `calc(${headerHeightWithOffsetTop}px)`,
                    lg: `calc(${headerHeightWithOffsetTop}px + 24px)`,
                  },
                }}
              >
                <TextSidebarMenu menuEntries={sidebarHeadlines} isSticky={isSticky} />
              </Grid>
              <Grid item xs={0} sm={1} />
            </>
          )}
          <Grid
            item
            xs={12}
            md={!showSidebarMenu && hasRichTextTable ? maxGridSize : defaultContentGridSize}
            display={{ xs: 'flex', sm: 'block' }} // this affects the richtext UI with images
            flexDirection={{ xs: 'column', sm: 'row' }} // this affects the richtext UI on mobile
            mx={shouldSetAutoMargin ? 'auto' : 'unset'}
          >
            {image && (
              <Box sx={styles.imageContainer}>
                <ImageWrapper
                  nextImageComponent={Image}
                  image={getImageWithFallback(image, FALLBACK_IMAGES.DEFAULT_4_3_RATIO)}
                  loader={setImageLoader(isProductImage, devicePixelRatio)}
                  sx={styles.image}
                  data-cy="text-image"
                />
              </Box>
            )}
            <Box
              sx={{
                marginBottom: '0px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                'li > p': {
                  mb: '0px',
                },
                scrollMarginTop: {
                  xs: `calc(${headerHeightWithOffsetTop}px + ${sectionHeaderTopOffset}px + 24px)`,
                  lg: `calc(${headerHeightWithOffsetTop}px + 40px)`,
                },
              }}
              data-cy="text-body"
            >
              <RichText richTextDocument={richTextDocument} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  )
}

export default TextBody
